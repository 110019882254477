<template>
    <v-app>
        <app-bar v-if="AppStore.started_app"></app-bar>
        <v-main>
            <v-slide-x-transition mode="out-in">
                <router-view></router-view>
            </v-slide-x-transition>
            <v-snackbar
                    v-model="snackbar"
                    :timeout="timeout"
                    :color="color"
            >
                <div v-html="text"></div>

                <template v-slot:action="{ attrs }">
                    <v-btn
                            color="blue"
                            icon
                            v-bind="attrs"
                            @click="snackbar = false"
                    >
                        X
                    </v-btn>
                </template>
            </v-snackbar>
        </v-main>
        <v-footer app
                  v-if="AppStore.started_app && $route.name === 'mb-1-home' && !_.isEmpty(AppStore.data.general.client_logo)"
                  padless
                  :color="!AppStore.data.general.dark_mode ? 'white' : ''"
        >
            <v-container fluid>
                <v-row id="logo-client">
                    <v-col class="">
                        <v-img
                                max-height="220"
                                max-width="165"
                                id="app-bar-logo"
                                style="display:block;margin: 0 auto;"
                                :src="AppStore.data.general.client_logo"
                        ></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>

        <v-footer app v-if="AppStore.started_app && $route.name === 'mb-1-questions'" padless id="footer" :color="!AppStore.data.general.dark_mode ? 'white' : ''">
            <v-container fluid>
                <v-row id="cgu-row">
                    <v-col class="text-center pa-1" id="cgu">
                        <div v-html="AppStore.data.configuration[AppStore.locale].information_txt"></div>
                        <span v-html="AppStore.data.configuration[AppStore.locale].cgu_txt"></span> {{ ' ' }}
                        <span v-html="AppStore.data.configuration[AppStore.locale].cgu_btn"
                              @click="AppStore.toggleCGU = !AppStore.toggleCGU" class="font-weight-bold"></span>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </v-app>
</template>

<script>
import AppStore from "../../../stores/AppStore";
import AppBar from "../../../components/mb/AppBar.vue";
import RatingsCarousel from "../../../components/communs/RatingsCarousel.vue";
import _ from "lodash";
import {EventBus} from "../../../services/Request";
import Helpers from "../../../services/Helpers";
import ModelLogic from "../../../logics/ModelLogic";
import moment from "moment";

export default {
    name: 'Layout',

    mounted() {
        if (!_.includes(['mb-1-home', 'mb-1-token'], this.$route.name) && !AppStore.started_app) {
            this.$router.push({name: 'mb-1-home'})
        }

        EventBus.$on('show-snackbar', (data) => {
            this.snackbar = false; //reset todo need stacked snack
            this.text = data.text || 'No information';
            this.timeout = data.timeout || 2000;
            this.color = data.color || 'green';
            this.snackbar = true;
        });
    },

    created() {
        EventBus.$on('getApp', (data) => {
            this.getApp(data)
        });

        EventBus.$on('sendRating', () => {
            this.sendRating('EventBus created')
        });
    },

    components: {
        AppBar,
        RatingsCarousel
    },

    data: () => ({
        AppStore,
        snackbar: false,
        text: '',
        timeout: 2000,
        color: 'primary',
        env: process.env.VUE_APP_ENV,
        appDomain: process.env.VUE_APP_APPS_HOST,
        checkingID: null,
        timeoutID: null,
    }),

    computed: {
        _() {
            return _;
        },
    },

    watch: {
        $route(to, from) {
            const pathTo = to.name.split('-').slice(0, 3)
            const pathFrom = from.name.split('-').slice(0, 3)
            if (pathTo[2] === 'appreciation' && pathFrom[2] === 'timeline') {
                if (AppStore.mobileAlreadyPostRating) {
                    this.$router.push({name: 'mb-1-home', query: AppStore.query_params})
                }
            }

            if (pathTo[2] === 'home' && pathFrom[2] === 'timeline') {
                this.resetRating()
            }

            if (pathTo[2] === 'home') {
                this.setQuestionAnswerFromUrlParams()
            }
        }
    },

    methods: {
        resetRating() {
            AppStore.rating = _.cloneDeep(AppStore.defaultRating)
        },

        sendRating(from) {
            if (AppStore.is_connected) {
                AppStore.rating.posted_at = moment().utc().format("DD/MM/YYYY HH:mm:ss")
                AppStore.rating.device_id = AppStore.data.device ? AppStore.data.device.id : null
                AppStore.rating.source = AppStore.data.source
                AppStore.rating.app_id = AppStore.data.id
                AppStore.rating.locale = AppStore.locale
                AppStore.rating.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                let ratingToSend = AppStore.rating
                ratingToSend.uuid = Helpers.uuidv4()

                ModelLogic
                    .store('ratings/post/liz', ratingToSend)
                    .then(() => {
                        // this.resetRating()
                        if (AppStore.data.general.post_single_rating) {
                            AppStore.mobileAlreadyPostRating = true;
	                        this.$cookie.set(AppStore.data.slug, moment().add(AppStore.data.general.cookie_expiration, 'hours'), { expires: new Date(moment().add(AppStore.data.general.cookie_expiration, 'hours')) });
                        }
                        // AppStore.db.remove(rating.id, rating.value.rev)
                    })
                    .catch((error) => {
                        // if (error.response.data.errors) {
                        //     this.$refs.observer.setErrors(error.response.data.errors);
                        // }
                        console.error(error)
                    })
            }
        },

        async getApp(data) {
            if (AppStore.is_connected) {
                await ModelLogic
                    .getOne('apps', data.type + '/' + data.model + '/' + data.slug, {
                        // with: ['unit.ratings.data', 'unit.ratings.consumer', 'unit.ratings.contents', 'unit.ratings.answers', 'unit.statistics']
                    })
                    .then((apps) => {
                        AppStore.data = apps
                        this.$vuetify.theme.dark = AppStore.data.general.dark_mode
                        if (AppStore.data.general.font_override) {
                            document.head.insertAdjacentHTML("beforeend", '<link href="' + AppStore.data.general.font_override + '" rel="stylesheet"></link>')
                        }
                        document.head.insertAdjacentHTML("beforeend", '<style>' + AppStore.data.general.css_override + '</style>')
                        AppStore.started_app = true

                        if (!data.noredirect && this.$route.name !== 'mb-1-home') {
                            this.$router.push({
                                name: 'mb-1-home',
                                params: {slug: data.slug},
                                query: data.params
                            })
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                        AppStore.app_is_loading = false;
                        // AppStore.started_app = false
                        EventBus.$emit('show-snackbar', {
                            text: 'Application non trouvée',
                            timeout: 10000,
                            color: 'error'
                        })
                    }).finally(() => {
                        if (AppStore.started_app) {
                            this.setHiddenQuestions();
                            this.setQuestions()
                            // this.startChecking()
                        }

                        AppStore.mobileAlreadyPostRating = !!(AppStore.data.general.post_single_rating && this.$cookie.get(data.slug));
                        AppStore.app_is_loading = false;
                        AppStore.is_first_launch = false;

                    })

            } else {
                console.log("Pas de connexion internet (getApp)")
            }
        },

        async setQuestions() {
            AppStore.appreciation_questions = await this.filterQuestions('appreciation')
            AppStore.profil_questions = await this.filterQuestions('profil')
            AppStore.context_questions = await this.filterQuestions('context')
            AppStore.hiddenQuestions = await this.filterHiddenQuestions()
            AppStore.bookmarkedQuestions = await this.filterBookmarkedQuestions()
            AppStore.conditionedQuestion = await this.filterConditionedQuestion()
            await this.setQuestionAnswerFromUrlParams()
        },

        async filterQuestions(section) {
            return _.filter(AppStore.data.questions, function (q) {
                if (q.section === section && !q.is_hidden && !q.is_bookmarked) {
                    q.mustBeShow = true;
                    return q
                }
            });
        },

        async filterConditionedQuestion() {
            return _.filter(AppStore.data.questions, (q) => {
                if (!_.isEmpty(q.conditional_question_id)) {
                    q.mustBeShow = false;
                    return q
                }
            });
        },

        async filterBookmarkedQuestions() {
            return _.filter(AppStore.data.questions, (q) => {
                if (q.is_bookmarked) {
                    q.mustBeShow = true;
                    return q
                }
            });
        },

        async filterHiddenQuestions() {
            return _.filter(AppStore.data.questions, function (q) {
                if (q.is_hidden) {
                    q.mustBeShow = false;
                    return q
                }
            });
        },

        async setQuestionAnswerFromUrlParams() {
            if (!_.isEmpty(this.$route.query)) {
                AppStore.query_params = this.$route.query;
                _.forEach(Object.entries(this.$route.query), (param, index) => {
                    _.forEach([...AppStore.hiddenQuestions,...AppStore.appreciation_questions, ...AppStore.profil_questions, ...AppStore.context_questions], (questions, index) => {
                        if (questions.short_name === param[0]) {
                            this.$set(AppStore.rating.questions_answers, questions.id, {
                                question_id: questions.id,
                                content: param[1]
                            })
                        }
                    });
                });
            }
        },

        setHiddenQuestions() {
            _.forEach(AppStore.hiddenQuestions, (hiddenQuestion, index) => {
                if (hiddenQuestion.short_name === param[0]) {
                    this.$set(AppStore.rating.questions_answers, hiddenQuestion.id, {
                        question_id: hiddenQuestion.id,
                        content: param[1]
                    })
                }
            });
        }
    }
};
</script>
<style>
.pointer {
    cursor: pointer;
}

.revert-btn {
    background-color: white;
}

#comment-help:before {
    opacity: 0.1
}

.timeline-row {
    border-radius: 8px;
}

#home-title {
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}

.carrousel-stars button, .timeline-row button {
    padding: 0 !important;
}

#offline-icon {
    color: red;
}

#close-cgu::before {
    font-weight: bold;
}

#appreciation-page .v-chip:hover::before {
    opacity: 0 !important;
}

#app-bar-logo .v-image__image--cover {
    background-size: contain !important;
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 1px;
}

#cgu {
    font-size: 10px;
}

.v-application .primary--text {
    color: inherit !important;
    caret-color: inherit !important;
}
</style>